<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
            :inline="true"
            class="searchBoxForm"
            size="small"
            label-position="right"
          >
            <el-form-item label="SCADA场站名称" class="searchItem">
              <el-input
                v-model="pageParams.scadaStationName"
                placeholder="请输入"
                clearable
                class="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="当前状态" class="searchItem">
              <el-select
                v-model="pageParams.bindStatus"
                placeholder="请选择"
                clearable
                class="search"
                @change="search"
              >
                <el-option
                  v-for="(item, index) in optionsStatus"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
           
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="search" size="mini"
                >查询</el-button
              >
              <el-button type="warning" @click="restSearch" size="mini"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="contain">
        <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%; margin-top: 10px"
          border
          :header-cell-style="{
            color: '#333',
            fontFamily: 'MicrosoftYaHeiUI',
            fontSize: '14px',
            fontWeight: 900,
            textAlign: 'left',
            background: '#f8f8f9',
          }"
          height="calc(100vh - 340px)"
          :stripe="true"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="scadaStationCode"
            label="SCADA场站编号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="scadaStationName"
            label="SCADA场站名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="scadaRouteName"
            label="线路名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="scadaType"
            label="进出站类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="bindStatus"
            label="当前状态"
            show-overflow-tooltip
          >
          <template  slot-scope="{ row }">
              {{row.bindStatus==1?'未绑定':'已绑定'}}
          </template>
          </el-table-column>
          <el-table-column
            prop="stationName"
            label="场站名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="channelType"
            label="进出站类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="channelName" label="进出站名称" show-overflow-tooltip>
          </el-table-column>
          
          <el-table-column label="操作" align="left" width="100">
            <template slot-scope="{ row }">
              <el-button type="text" @click="getDetail(row)" size="mini"
                >{{row.bindStatus==1?'绑定':'解绑'}}</el-button
              >
              
            </template>
          </el-table-column>
        </el-table>
        <div class="total">
          <el-pagination
            background
            layout="total, prev, pager, next,sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageParams.current"
            :page-sizes="[ 30, 50, 100]"
            :page-size="pageParams.size"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      title="数据绑定"
      :visible.sync="dialogVisible"
      :modal="true"
      :close-on-click-modal="false"
      width="700px"
      @close="close()"
      custom-class="dialog-class"
    >
      <div style="font-size:16px;font-weight: bold; margin-bottom: 10px;">SCADA信息</div>
      <el-form
        ref="form"
        :model="formStation"
        :rules="rule"
        label-width="130px"
        size="mini"
        :disabled="!isEdit"
      >
      <el-row>
        <el-col span="8">
        <el-form-item label="SCADA场站编号:">
          {{formStation.scadaStationCode}}
        </el-form-item>
        </el-col>
        <el-col span="8">
        <el-form-item label="SCADA场站名称:">
          {{formStation.scadaStationName}}
        </el-form-item>
        </el-col>
        <el-col  span="8">
        <el-form-item label="线路名称:">
          {{formStation.scadaRouteName}}
        </el-form-item>
        </el-col>
        <el-col  span="24">
        <el-form-item label="进出站类型:" >
          {{formStation.scadaType}}
        </el-form-item>
        </el-col>
      </el-row>
      </el-form>
       <div style="font-size:16px;font-weight: bold; margin-bottom: 10px;">绑定实例</div>
       <div >
          <el-form
            :inline="true"
            class="searchBoxForm"
            size="small"
            label-position="right"
          >
          <el-form-item label="场站分类" class="searchItem">
              <el-select
              style="width:100px"
                v-model="pageParamsAdd.stationType"
                placeholder="请选择"
                clearable
                class="search"
                @change="search"
              >
                <el-option
                  v-for="(item, index) in optionsStation"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="场站名称" class="searchItem">
              <el-input
              style="width:100px"
                v-model="pageParamsAdd.stationName"
                placeholder="请输入"
                clearable
                class="searchAdd"
              ></el-input>
            </el-form-item>
            
           
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="searchAdd" size="mini"
                >查询</el-button
              >
              <el-button type="warning" @click="restSearchAdd" size="mini"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div >
        <el-table
          ref="tableData"
          :data="tableDataAdd"
          style="width: 100%; margin-top: 10px"
          border
          :header-cell-style="{
            color: '#333',
            fontFamily: 'MicrosoftYaHeiUI',
            fontSize: '14px',
            fontWeight: 900,
            textAlign: 'left',
            background: '#f8f8f9',
          }"
          :stripe="true"
        >
          <el-table-column
            prop="stationName"
            label="场站名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="stationType"
            label="场站分类"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="channelType"
            label="进出站"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="channelName"
            label="进出站名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="left" width="100">
            <template slot-scope="{ row }">
              <el-button type="text" @click="binding(row)" size="mini"
                >绑定</el-button
              >
              
            </template>
          </el-table-column>
        </el-table>
        <div class="total">
          <el-pagination
            background
            layout="total, prev, pager, next,sizes, jumper"
            :total="totalAdd"
            @size-change="handleSizeChangeAdd"
            @current-change="handleCurrentChangeAdd"
            :current-page.sync="pageParamsAdd.current"
            :page-sizes="[ 30, 50, 100]"
            :page-size="pageParamsAdd.size"
          >
          </el-pagination>
        </div>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins";
import { bindGisPoint, selectStationGisPointList, selectStationRealtimeList, unBindGisPoint } from "../apis/base";
import '../style/skin.scss'
export default {
  name: "PipeLineList",
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      total: null,
      tableData: [], //  表格数据

      pageParamsAdd: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      totalAdd: null,
      tableDataAdd: [], //  表格数据
     
      form: {},
     
      optionsStatus: [
        {
          value: 2,
          label: '已绑定'
        },
        {
          value: 1,
          label: '未绑定'
        }
      ],
      optionsStation: [
        {
          value: 1,
          label: '供气场站'
        },
        {
          value: 2,
          label: '用户场站'
        }
      ],
     dialogVisible:false,
     formStation:{}
    };
  },
  mounted() {
    this.loadList(this.pageParams);
    // this.getCodeName();
  },
  methods: {
    getDetail(row){
      if(row.bindStatus == 1){
        this.dialogVisible = true
        this.formStation = row;
        this.loadListAdd(this.pageParamsAdd)
      }else{
          this.$confirm(`确认解绑吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
          }).then(() => {
            unBindGisPoint({id:row.id,pointId:row.pointId}).then(res=>{
              if(res.code==200){
                this.$message.success('解绑成功！')
                this.pageParams.current = 1;
                this.loadList(this.pageParams)
                this.close()
              }else{
                this.$message.error(res.msg)
              }
              
            })
          });
      }
        
    },
    binding(row){
        this.$confirm(`确认绑定？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        }).then(() => {
          bindGisPoint({id:this.formStation.id,pointId:row.pointId}).then(res=>{
            if(res.code==200){
              this.$message.success('绑定成功！')
              this.pageParams.current = 1;
              this.loadList(this.pageParams)
              this.close()
            }else{
              this.$message.error(res.msg)
            }
          })
        });
    },
    close(){
      this.formStation = {};
      this.tableDataAdd = [];
      this.pageParamsAdd = {current:1,size: 100}
      this.dialogVisible = false
    },
     loadListAdd(obj) {
      selectStationGisPointList(obj).then((res) => {
        this.tableDataAdd = res.data.records;
        this.totalAdd = res.data.total;
      });
    },
    searchAdd() {
      this.pageParamsAdd.current = 1;
      this.loadListAdd(this.pageParamsAdd);
    },
    restSearchAdd() {
      this.pageParamsAdd = {
        current: 1,
        size: 100,
      };
      this.loadListAdd(this.pageParamsAdd);
    },
     handleCurrentChangeAdd(current) {
      this.pageParamsAdd.current = current;
      this.loadListAdd(this.pageParamsAdd);
    },
    handleSizeChangeAdd(val) {
      this.pageParamsAdd.size = val;
      this.handleCurrentChangeAdd(1);
    },
     loadList(obj) {
      selectStationRealtimeList(obj).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        current: 1,
        size: 100,
      };
      this.loadList(this.pageParams);
    },
     handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool {
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
  height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh - 120px);
}
.search {
  width: 215px;
}
.contain {
  height: 690px;
}
.total {
  margin-left: 15px;
  margin-top: 10px;
}
.table {
  overflow: auto;
  height: 520px;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  .el-input {
    width: 190px;
  }
  .el-select {
    width: 190px;
  }
}
.btn {
  text-align: right;
}
</style>